<template>
  <v-container fluid>
    <v-row>
      <v-col sm="6" md="4">
        <v-card>
          <v-card-title><h3>Phone Status</h3></v-card-title>
          <v-card-text>
            <v-alert :type="status">{{ statusText }}</v-alert>
            <v-btn @click="setup" color="success">Online</v-btn>
            <v-btn @click="offline" color="error" class="float-right">Offline</v-btn>
          </v-card-text>
        </v-card>
        <br/>
        <v-card>
          <v-card-title><h3>Phone Messages</h3></v-card-title>
          <v-card-text>
            <v-card v-for="(item, counter) in messages" :key="counter" style="margin: 5px;" outlined >
              <v-card-text>
                <v-btn @click="get_recording(item.sid)" style="float: right;" color="orange" x-small>Fetch Recording</v-btn>
                <b>From:</b>
                <span style="clear: none;" v-if="item.name">{{ item.name }} (</span>
                <span style="clear: none;" v-if="item.from">{{ item.from_formatted }}</span>
                <span style="clear: none;" v-if="item.name">)</span>
                <span>&nbsp;</span>
                <b>Date:</b> {{ item.created | date }}<br/>
                <span>{{item.text}}</span>
                <span><br/><b>Recording ID:</b> {{item.sid}}</span>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="get_messages" color="success">Get Messages</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="6" md="8">
        <v-card>
          <v-card-title><h3>Call Status</h3></v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="phone" label="Phone Number" hint="Include + and country code"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="phoneStartText" label="Call Start" readonly dense
                              append-icon="mdi-content-copy" @click:append="copyText(phoneStartText)"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="phoneStopText" label="Call End" readonly dense
                              append-icon="mdi-content-copy" @click:append="copyText(phoneStopText)"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="phoneDuration" label="Duration (Min)" readonly dense
                              append-icon="mdi-content-copy" @click:append="copyText(phoneDuration)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="answer" :disabled="incoming" color="primary" class="ma-1">Answer</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="call" :disabled="on_call" color="primary" class="ma-1">Call</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="hangup" :disabled="!on_call" color="error" class="ma-1 float-right">Hangup</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapState} from "vuex";
import {Device} from "twilio-client"

export default {
  name: "Dashboard",
  computed: {
    ...mapState(['user', 'twilio', 'messages']),
  },
  data() {
    return {
      device: new Device(),
      connection: null,
      incoming: true,
      on_call: false,
      status: 'error',
      statusText: 'Offline',
      phone: null,
      phoneStart: null,
      phoneStartText: null,
      phoneStop: null,
      phoneStopText: null,
      phoneDuration: null,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    phoneStart: function (newValue, old) {
      this.phoneStartText = new Date(newValue).toLocaleString('en-US')
    },
    // eslint-disable-next-line no-unused-vars
    phoneStop: function (newValue, old) {
      this.phoneStopText = new Date(newValue).toLocaleString('en-US')
    }
  },
  methods: {
    copyText(value) {
      navigator.clipboard.writeText(value)
    },
    answer() {
      if (this.connection) {
        this.connection.accept()
      }
    },
    call() {
      this.device.connect({
        phoneNumber: this.phone
      })
    },
    hangup() {
      this.device.disconnectAll()
    },
    offline() {
      this.device.destroy()
    },
    get_messages() {
      this.$store.dispatch('getMessages')
    },
    get_recording(sid) {
      this.$store.dispatch('getRecording', sid)
    },
    setup() {
      this.$store.dispatch('getTwilio').then(() => {
        this.device.setup(this.twilio.token, {
          codecPreferences: ['opus', 'pcmu'],
          fakeLocalDTMF: true,
          closeProtection: true,
          debug: false,
        })
            .on('ready', () => {
              this.status = 'success'
              this.statusText = 'Online / Ready'
            })
            .on('incoming', (connection) => {
              this.connection = connection
              console.error(connection)
              this.incoming = false
              this.on_call = true
            })
            .on('cancel', () => {
              this.connection = null
              this.incoming = true
              this.on_call = false
            })
            .on('connect', () => {
              this.on_call = true
              this.phoneStart = Date.now()
              this.phoneStop = null
              this.phoneDuration = null
            })
            .on('disconnect', () => {
              this.connection = null
              this.incoming = true
              this.on_call = false
              this.phoneStop = Date.now()
              this.phoneDuration = Math.round(((this.phoneStop - this.phoneStart) / 1000) / 60)
            })
            .on('offline', () => {
              console.log('offline')
              this.status = 'error'
              this.statusText = 'Offline'
            })
            .on('error', (error) => {
              console.log(error)
              this.$toasted.error(error.message).goAway(2000)
              this.device.destroy()
            })
      })
    }
  },
  created() {
    this.$store.dispatch('getUser')
  }
}
</script>

<style scoped>

</style>
