import Vue from 'vue'
import VueRouter from 'vue-router'
import Base from "@/Base";
import Dashboard from "@/views/Dashboard";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Base,
        redirect: '/dashboard',
        meta: {requiresAuth: true},
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: Dashboard
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('token') === null) {
          next('/login')
      } else {
          next()
      }
  } else {
      next()
  }
})

export default router
