<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-title>
        <v-img src="@/assets/logo.small.png" width="200" min-width="200"/>
      </v-app-bar-title>
      <v-spacer/>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="user"
              text
          >
            <v-icon>mdi-account-circle</v-icon>
            {{ user.first_name }} {{ user.last_name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Base",
  data: () => ({
    device: null
  }),
  computed: {
    ...mapState([
      'user',
    ])
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push({name: 'login'})
      })
    },
  },
  created() {
    if (this.user === null) {
      this.$store.dispatch('getUser').catch((error) => {
        console.log(error)
        this.$router.push({name: 'login'})
      })
    }
  }
}
</script>

<style scoped>

</style>
