import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#137fb6'
            },
            dark: {
                primary: '#137fb6'
            }
        }
    }

});
