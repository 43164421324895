import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        token: null,
        user: null,
        twilio: null,
        messages: null
    },
    mutations: {
        SET_TOKEN(store, token) {
            store.token = token
            if (token === null) {
                localStorage.removeItem('token')
                this._vm.$axios.defaults.headers.common['Authorization'] = token;
            } else {
                localStorage.setItem('token', token)
                this._vm.$axios.defaults.headers.common['Authorization'] = 'token ' + token;
            }
        },
        SET_USER(store, user) {
            store.user = user
        },
        SET_TWILIO(store, twilio) {
            store.twilio = twilio
        },
        SET_MESSAGES(store, messages) {
            store.messages = messages
        }
    },
    actions: {
        login({commit}, {username, password}) {
            return this._vm.$axios.post('/api/auth/login/', {username: username, password: password})
                .then((response) => {
                    commit('SET_TOKEN', response.data.key)
                })
        },
        logout({commit}) {
            return this._vm.$axios.post('/api/auth/logout/')
                .then((response) => {
                    commit('SET_TOKEN', null)
                    commit('SET_USER', null)
                    commit('SET_TWILIO', null)
                    this._vm.$toasted.success(response.data.detail).goAway(2000)
                })
        },
        getUser({commit}) {
            return this._vm.$axios.get('/api/auth/user/')
                .then((response) => {
                    commit('SET_USER', response.data)
                })
                .catch((error) => {
                    commit('SET_TOKEN', null)
                    this._vm.$toasted.error(error).goAway(2000)
                })
        },
        getTwilio({commit}) {
            return this._vm.$axios.get('/api/voice/token/')
                .then((response) => {
                    commit('SET_TWILIO', response.data)
                })
                .catch((error) => {
                    commit('SET_TWILIO', null)
                    this._vm.$toasted.error(error).goAway(2000)
                })

        },
        getMessages({commit}) {
            return this._vm.$axios.get('/api/voice/messages/')
                .then((response) => {
                    commit('SET_MESSAGES', response.data)
                })
                .catch((error) => {
                    // commit('SET_TWILIO', null)
                    this._vm.$toasted.error(error).goAway(2000)
                })
        },
        // eslint-disable-next-line no-unused-vars
        getRecording({commit}, sid) {
            return this._vm.$axios.get('/api/voice/download/'+sid+'/')
                .then((response) => {
                    let b64 = Buffer.from(response.data, 'binary')
                    let audio = new Audio('data:audio/x-wav;base64,' + b64.toString('base64'))
                    audio.load()
                    audio.play().catch((error)=>{alert(error)})

                })
                .catch((error) => {
                    // commit('SET_TWILIO', null)
                    this._vm.$toasted.error(error).goAway(2000)
                })
        }
    },
    modules: {}
})
